.browse-the-library-wrapper {
    background-color: rgba(3, 74, 115, 1);

}

.browse-the-library-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.browse-the-library-title {
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
}

.browse-the-library-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 30px;
}

.browse-the-library-info-wrapper {
    max-width: 620px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
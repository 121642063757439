.contact-us-main-container-wrapper {
    display: flex;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 20px;
}

.contact-us-info-block-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 8px;
}

.contact-us-info-block-desc {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgba(136, 139, 150, 1);
    margin-bottom: 25px;
}

.contact-us-info-block-wrapper {
    gap: 40px;
    display: flex;
    flex-direction: column;
}

.contact-us-info-block-phone-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
}

.contact-us-info-block-email-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.contact-us-form-container {
    width: 100%;
    display: grid;
    gap: 0 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
        "fullname phone"
        "email message"
        "userType message"
        "companyName companyWebSite";
}

.contact-us-for-fullname-container {
    grid-area: fullname;
}

.contact-us-for-email-container {
    grid-area: email;
}

.contact-us-for-phone-container {
    grid-area: phone;
}

.contact-us-for-user-type-container {
    grid-area: userType;
}

.contact-us-for-company-name-container {
    grid-area: companyName;
}

.contact-us-for-message-container {
    grid-area: message;
}

.contact-us-for-company-website-container {
    grid-area: companyWebSite;
}

.contact-us-submit-and-term-policy-container {
    width: 50%;
}

.contact-us-input-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: rgba(43, 54, 116, 1);
    margin-bottom: 10px;
}

.contact-us-item-container {
    max-width: 410px;
    width: 100%;
    margin-top: 6px;
}

.contact-us-radio-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
}

.contact-us-submit-btn {
    width: 100%;
}

// @media (max-width: 1500px) {

//     .contact-us-main-container-wrapper,
//     .contact-us-hero-img-info-wrapper,
//     .contact-us-divider {
//         padding-left: 50px;
//         padding-right: 50px;
//     }
// }

@media (max-width: 890px) {
    .contact-us-form-container {
        width: 100%;
        display: grid;
        gap: 0 20px;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            "fullname"
            "email"
            "phone"
            "userType"
            "companyName"
            "companyWebSite"
            "message"
        ;
    }

    .contact-us-main-container-wrapper {
        flex-direction: column;
        gap: 35px;
    }

    .contact-us-submit-and-term-policy-container {
        max-width: 410px;
        width: 100%;
    }
}

@media (max-width: 670px) {

    .contact-us-main-container-wrapper,
    .contact-us-hero-img-info-wrapper,
    .contact-us-divider {
        padding-left: 20px;
        padding-right: 20px;
    }

    .contact-us-submit-and-term-policy-container {
        max-width: 410px;
        width: 100%;
    }
}

.contact-us-divider {
    box-sizing: border-box;
    width: 100%;
    height: 1px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

.contact-us-divider::after {
    content: "";
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.09);
}

.reset-a {
    all: unset;
}
.campaign-builder-main-header-container {
  display: flex;
  justify-content: space-between;
}

.campaign-builder-main-upload-section {
  display: flex;
  gap: 15px;
}

.campaign-builder-main-upload-item {
  background-color: #e8eff2;
  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-builder-main-edit-item {
  background-color: rgba(215, 161, 72, 1);
  border-radius: 50%;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.campaign-builder-media-main-container {
  width: 100%;
  border-radius: 20px;
  display: flex;
  gap: 16px;
  position: relative;
  direction: ltr;
}

.campaign-builder-media-img-left {
  flex-basis: 70%;
  background-color: #e8f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.campaign-builder-media-img-one {
  flex-basis: 100%;
  background-color: #e8f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.campaign-builder-media-img-right {
  flex-basis: 30%;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.campaign-builder-media-img-item {
  background-color: #e8f4f8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.campaign-builder-media-img-item img {
  width: 47px;
}

.label-stamp-container {
  position: absolute;
  top: 30px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.meadia-upload-panel {
  position: absolute;
  bottom: -22px;
  display: flex;
}

.meadia-upload-panel-item {
  box-shadow: 0px 4px 7px 0px #00000021;
  background-color: #ffffff;
  color: #808080;
  display: flex;
  gap: 6px;
  padding: 12px 17px;
  cursor: pointer;
}

.meadia-upload-panel-item img {
  width: 22px;
  height: 19px;
}

.campaign-builder-main-info {
  width: 100%;
}

.campaign-builder-main-info-title {
  font-weight: 700;
  line-height: 22px;
}

.campaign-builder-main-info-adress {
  color: #0000009e;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  margin-top: 12px;
  margin-bottom: 14px;
}

.campaign-builder-main-propertystatus-container {
  display: flex;
  margin-top: 25px;
}

.campaign-builder-main-propertystatus-container-mob {
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
}

.campaign-builder-main-propertystatus-container-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}

.campaign-builder-main-propertystatus-left-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.campaign-builder-main-info-header {
  display: flex;
  justify-content: space-between;
}

.campaign-builder-main-info-brochure-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #034a73;
  gap: 4px;
  cursor: pointer;
}

.campaign-builder-main-info-wrapper {
  display: flex;
  margin-top: 57px;
  justify-content: space-between;
  gap: 20px;
  direction: ltr;
}

.campaign-builder-agent-info-block {
  max-width: 300px;
  box-shadow: 0px 8px 10px 0px #00000021;
  width: 100%;
  height: fit-content;
  border-radius: 13px;
  padding: 30px 20px;
  box-sizing: border-box;
}

.campaign-builder-agent-info-block-name {
  display: flex;
  gap: 10px;
  align-items: center;
}

.campaign-builder-agent-info-block-agent-name {
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
}

.campaign-builder-agent-info-block-agent-role {
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: #034a73;
}

.schedule-a-viewing-btn {
  background: linear-gradient(90deg, #034A73 27.27%, #497690 92.59%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 26px;
  gap: 7px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.speak-to-agent-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 26px;
  gap: 7px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  color: #034a73;
  border: 1px solid #034a73;
}

.campaign-builder-agent-info-block-btns {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 23px;
}

.campaign-builder-main-info-price {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.campaign-builder-main-propertystatus-step {
  color: #616161;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
}

.campaign-builder-main-info-divider {
  background-color: #00000021;
  height: 1px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.campaign-builder-investment-highlights-items-block {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.campaign-builder-investment-highlights-item {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  align-items: center;
}

.campaign-builder-key-feature-items-block {
  gap: 17px;
  margin-top: 27px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  gap: 10px;
}

.campaign-builder-main-header {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.32px;
}

.campaign-builder-second-header {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.32px;
  color: #0000009e;
  word-break: break-all;
}

.campaign-builder-about-property-container {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.campaign-builder-main-location-name {
  display: flex;
  gap: 4px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 23px;
  margin-bottom: 20px;
}

.campaign-builder-get-direction {
  color: #034a73;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.32px;
  display: flex;
  gap: 7px;
  text-wrap: nowrap;
  align-items: center;
}

.campaign-builder-main-location-header-container {
  display: flex;
  justify-content: space-between;
}

.mode-switcher-wrapper {
  padding-top: 25px;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.preview-mode-switcher-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1340px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.preview-mode-switcher-toggle-btn {
  height: 60px;
  width: 412px;
  display: flex;
  position: relative;
  overflow: hidden;
  direction: ltr;
}

.empty-block100px {
  display: block;
  width: 100px;
}

.camapign-builder-mobile-header {
  background-color: rgba(5, 57, 87, 1);
  color: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  padding: 32px 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.camapign-builder-mobile-header-set-up {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
}

.campaign-builder-mobile-header-back-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
}

.lang-toggle-fixed-wrapper {
  background-color: rgba(3, 74, 115, 1);
  z-index: 100;
  border-radius: 50px;
  padding: 13px 13px;
  cursor: pointer;
}

.edit-btn-wrapper {
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  width: fit-content;
  gap: 7px;
  padding: 9px 20px;
  border-radius: 16.5px;
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}
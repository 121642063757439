.gallery-of-articles-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(316px, 1fr));
    gap: 25px;
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
}

.no-articles-container{
    padding-top: 50px;

}
.forgot-p-section {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}

.forgot-p-fingerprint-container {
  border: 1px solid rgba(163, 174, 208, 1);
  padding: 18.5px 14px;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-p-check-container {
  border: 1px solid rgba(163, 174, 208, 1);
  padding: 18.5px 14px;
  border-radius: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-p-check-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.forgot-p-fingerprint-container img {
  width: 23px;
  height: 23px;
}

.forgot-p-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
  flex: 1 1;
  min-height: 470px;
  max-width: 500px;
}

.forgot-p-title {
  font-size: 32px;
  font-weight: 700;
  margin-top: 35px;
  letter-spacing: -0.32px;
  line-height: 32px;
}

.forgot-p-desc {
  font-weight: 400;
  font-size: 16px;
  color: rgba(120, 125, 153, 1);
  letter-spacing: -0.32px;
  margin-top: 10px;
  line-height: 16px;
  margin-bottom: 30px;
}

.back-to-login-container {
  cursor: pointer;
  display: flex;
  margin-top: 24px;
  gap: 7px;
}

.step-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
  position: absolute;
  // bottom: 55px;
  bottom: 30px;
}

.step {
  transition: all 0.3s ease-in-out;
}

.forgot-p-email-checked-container {
  border: 1px solid rgba(163, 174, 208, 1);
  padding: 19px 15px 18px 15px;
  border-radius: 10px;
  width: fit-content;
}

.forgot-p-fingerprint-container img {
  width: 27px;
  height: 20px;
}

.forgot-p-reset-desc {
  font-weight: 400;
  font-size: 16px;
  color: rgba(120, 125, 153, 1);
  letter-spacing: -0.32px;
  line-height: 16px;
}

.forgot-p-send-email-desc-container {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 15px;
  margin-bottom: 25px;
}

.forgot-p-send-email-desc-email {
  color: rgba(43, 54, 116, 1);
  font-weight: 600;
  font-size: 16px;
  letter-spacing: -0.32px;
}

.forgot-p-lock-container {
  border: 1px solid rgba(163, 174, 208, 1);
  padding: 9.2px 10.8px;
  border-radius: 10px;
  width: fit-content;
}

.password-checks-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 11px;
  margin-bottom: 15px;
}

.password-checks-item-container {
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: -0.32px;
  line-height: 16px;
  margin-bottom: 10px;
}

.sign-up-term-policy {
  display: flex;
  // margin-top: 10px;
  gap: 11px;
  color: rgba(43, 54, 116, 1);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.32px;
  align-items: flex-start;
}

.not-registred-yet-container-item {
  font-size: 14px;
  letter-spacing: -0.32px;
  line-height: 26px;
}
.expore-block-container {
    background-color: rgba(3, 74, 115, 1);
    padding: 25px 20px 25px 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}

.expore-block-title-container {
    display: flex;
    justify-content: space-between;
}

.expore-block-title {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
}

.expore-block-desc {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.83);
    line-height: 18px;
}

.expore-block-arrow {
    background-color: rgba(252, 188, 92, 1);
    width: 37px;
    height: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
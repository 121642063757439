.campaign-view-mode-table-wrapper {
  // display: flex;
  // flex-direction: column;
  // width: 100%;

  .main-title {
    font-weight: 600;
    font-size: 22px;
  }

  .highlight-text {
    font-weight: 600;
  }

  .table-img {
    width: 310px;
    height: 205px;
    border-radius: 20px;
  }

  .table-btn {
    width: 130px;
    height: 42px;
    border-radius: 24px;
    background-color: rgba(3, 74, 115, 1);
    margin-top: auto;
  }

  .table-row td:first-child {
    padding-left: 100px;
  }

  .table-row td:last-child {
    padding-right: 100px;
  }

  .table-row-he td:first-child {
    padding-right: 100px;
  }

  .table-row-he td:last-child {
    padding-left: 100px;
  }

  @media (max-width: 568px) {
    .table-img {
      width: 200px;
      height: 123px;
    }

    .table-row td:first-child {
      padding-left: 20px;
    }

    .table-row td:last-child {
      padding-right: 20px;
    }

    .table-row-he td:first-child {
      padding-right: 20px;
    }

    .table-row-he td:last-child {
      padding-left: 20px;
    }
  }
}

.campaign-view-mode-calculator-wrapper {
  background-color: rgba(3, 74, 115, 0.06);
  border-radius: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

.campaign-view-mode-calculator-img {
  height: 500px;
  width: 40%;
  min-width: 250px;
}

.campaign-view-mode-calculator-wrapper .calculator-btn {
  width: 100%;
  height: 56px;
  border-radius: 16px;
  background-color: rgba(3, 74, 115, 1);
  align-self: end;
  text-transform: capitalize;
  font-size: 14px;
}

.calculator-final-sum {
  font-size: 20px;
  font-weight: 500;
}

.calculator-final-sum-blue {
  font-size: 24px;
  font-weight: 700;
  color: rgba(3, 74, 115, 1);
}

.compare-properties-title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: rgba(79, 129, 157, 1);
  margin: 0;
  text-align: center;
}

.compare-properties-desc {
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
}

.compare-title-container {
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.compare-title-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 40px;
}

.campaign-view-sticky-col {
  z-index: 1;
  position: sticky;
}

.table-cammaign-view-custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

.table-cammaign-view-custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.table-cammaign-view-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #aaadafab;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.table-cammaign-view-custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #aaadafab #f1f1f1;
}

.campaign-view-mode-calculator-invest-calc {
  font-weight: 600;
  color: rgba(79, 129, 157, 1);
  margin: 0;
}

.campaign-view-mode-calculator-desc {
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

.campaign-view-mode-calculator-title-and-desc-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.campaign-view-mode-calculator-filed-title {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.56);
}
.footer-container-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 700;
    font-size: 26px;
}

.footer-container-desc {
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.62);
}

.footer-container-nav-item {
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;

}

//new 
.footer-items-wrapper {
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 20px 20px;
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
}
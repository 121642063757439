.fp-inner-slider {
    .swiper-wrapper {
        height: 100%;
        border-radius: 20px;
        object-fit: "cover",

    }

    .swiper-pagination-bullet {
        background-color: rgba(104, 191, 210, 1);
    }
}

.featured-properties-title-container {
    display: flex;
    justify-content: space-between;
}

.featured-properties-titles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600
}

.featured-properties-arrow-circle {
    border: 1px solid rgba(3, 74, 115, 1);
    width: 36px;
    height: 36px;

    img::selection {
        background-color: transparent;
    }
}

.featured-properties-arrow-round {
    background-color: rgba(3, 74, 115, 1);

    img::selection {
        background-color: transparent;
    }

    width: 37px;
    height: 37px;
}

.featured-properties-arrow-round,
.featured-properties-arrow-circle {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featured-properties-arrows-container {
    display: flex;
    border-radius: 50%;
    align-items: center;
}

.featured-properties-arrows-container span {
    font-size: 24px;
    color: rgba(3, 74, 115, 1);
    margin-right: 12px;
    margin-left: 12px;
    font-weight: 700;
}

.featured-properties-main-picture-container {
    position: relative,

}

.featured-properties-main-picture-info-container {
    padding: 18px 16px 14px 22px;
    position: absolute;
    bottom: 18px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(60px);
    border-radius: 11px;
}

.featured-properties-main-picture-location {
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
    display: inline-block;

}

.featured-properties-additional-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.featured-properties-like {
    background-color: rgba(232, 239, 242, 1);
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.featured-properties-like-large {
    background-color: rgba(232, 239, 242, 1);
    width: 47px;
    height: 47px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

// .fade-in {
//     opacity: 1;
//     animation: pulse 0.3s;
// }

@keyframes pulse {
    0% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.featured-properties-additional-info-container div {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 400;
}

.featured-properties-see-info {
    position: absolute;
    bottom: 18px;
    background-color: rgba(255, 255, 255, 0.29);
    backdrop-filter: blur(60px);
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    padding: 9px 18px;
    border-radius: 24px;
    font-weight: 500;
}
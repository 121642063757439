.portfolio-error-container {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-right: 20px;
    padding-left: 20px;

}

.portfolio-loading-container {
    display: flex;
    justify-content: center;
    height: 300px;
}

.properties-top-info-container {
    display: flex;
    justify-content: space-between;
}

.day-remain-label-property-card {
    background-color: #FEA94D;
    border-radius: 16px;
    color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 20px;
    // right: -10px;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 15px;
    gap: 5px;
}

.day-remain-label-property-view {
    background-color: #FEA94D;
    border-radius: 16px;
    color: white;
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 88px;
    left: -9px;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 15px;
    gap: 5px;
}
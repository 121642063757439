.filter-side-bar-container {
    border-radius: 20px;
}

.filter-side-title-menu {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
}

.filter-side-title-menu span {
    font-size: 18px;
    margin-top: 26px;
    margin-bottom: 10px;
}

.filter-side-bar-filters-title {
    font-size: 14px;
    color: rgba(43, 54, 116, 1);
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
    display: block;
}

.filter-side-bar-search-btn {
    background-color: rgba(3, 74, 115, 1);
    border: none;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 500;
    padding-top: 19px;
    padding-bottom: 15px;
    width: 100%;
    border-radius: 16px;
    margin-top: 20px;
}

.filter-side-bar-filters-container{
    margin-bottom: 25px;
}

.filter-side-bar-container #tabpanel-2{
    height: 91%;
}
.campaign-builder-sidenav-wrapper {
  padding: 40px 20px 40px 20px;
}

.campaign-builder-sidenav-back {
  display: flex;
  color: rgba(220, 220, 236, 1);
  align-items: center;
  gap: 10px;
  line-height: 16px;
  cursor: pointer;
}

.campaign-builder-sidenav-back-mobile{
  display: flex;
  color: rgba(255, 255, 255, 1);
  align-items: center;
  gap: 10px;
  line-height: 16px;
  cursor: pointer;
}

.campaign-builder-sidenav-back-text {
  font-weight: 400;
}

.campaign-builder-light-blue-btn {
  color: #034A73;
  background-color: #E8F4F8;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  border-radius: 18px;
  box-sizing: border-box;
  gap: 7px;
  padding: 11px 20px;
  width: fit-content;
  cursor: pointer;
}

.campaign-builder-sidenav-close {
  cursor: pointer;
}

.campaign-builder-sidenav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.campaign-builder-sidenav-header-title {
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
}

.campaign-builder-sidenav-setup-item {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 23px 20px;
  color: rgba(255, 255, 255, 1);
  background-color: rgba(41, 102, 138, 0.72);
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  cursor: pointer;
}

.campaign-builder-sidenav-main-content-set-up {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.campaign-builder-sidenav-white-btn {
  color: rgba(43, 54, 116, 1);
  background-color: rgba(255, 255, 255, 1);
  padding: 19px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
}

.campaign-builder-sidenav-blue-btn {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(5, 57, 87, 1);
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 19px;
  text-align: center;
  border-radius: 16px;
  cursor: pointer;
}

.campaign-builder-sidenav-main-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  box-sizing: border-box;
}

.campaign-builder-sidenav-footer-btns-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 35px;
}

.campaign-builder-sidenav-field-name {
  font-size: 14px;
  line-height: 14px;
  color: rgba(220, 220, 236, 1);
  margin-bottom: 10px;
  font-weight: 500;
}

.campaign-builder-sidenav-field-name-main {
  font-size: 18px;
  line-height: 18px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 25px;
  font-weight: 500;
  letter-spacing: -0.32px;
}

.campaign-builder-sidenav-settings-fileds-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.campaign-builder-sidenav-settings-fileds-item {
  width: 100%;
}

.campaign-builder-sidenav-duration-select-container {
  display: flex;
  background-color: rgba(232, 239, 242, 1);
  border-radius: 16px;
}

.campaign-builder-sidenav-duration-select-line {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.13);
  margin-top: 13px;
  margin-bottom: 13px;
}

.campaign-builder-errors {
  color: rgba(250, 99, 99, 1);
  font-size: 14px;
  margin-top: 6px;
}

.suggestion-item.highlighted {
  background-color: rgba(5, 71, 110, 0.16);
}

.campaign-builder-main-container-header-title {
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 3px;
}

.campaign-builder-main-container-header-desc {
  color: #0000009e;
}

.campaign-builder-main-container-expire-time {
  display: flex;
  font-size: 16px;
  margin-bottom: 12px;
}

.campaign-builder-main-container-expire-time span {
  font-weight: 700;
}

.drag-and-drop-area-wrapper {
  background-color: rgba(31, 90, 123, 0.72);
  height: 130px;
  border-radius: 16px;
  border: 1px dotted rgba(211, 215, 237, 0.5);
}

.drag-and-drop-area-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.drag-and-drop-area-upload-img {
  width: 30px;
  height: 30px;
}

.drag-and-drop-area-upload-img-text {
  max-width: 215px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 14px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
}

.drag-and-drop-file-name {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 10px;
}

.drag-and-drop-file-container {
  width: 100%;
  max-width: 175px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.drag-and-drop-file-container-img-container {
  position: relative;
}

.drag-and-drop-file-container-pdf-container {
  display: flex;
}

.drag-and-drop-pdf-name {
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.campaign-builder-sidenav-switcher-container {
  display: flex;
  gap: 24px;
}

.campaign-builder-sidenav-add-new-btn {
  background-color: rgba(255, 255, 255, 0.15);
  display: inline-block;
  padding: 8px 20px;
  border-radius: 16px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 7px;
}

.campaign-builder-sidenav-empty-btn {
  width: 53px;
}

.campaign-builder-sidenav-number-of-char {
  color: rgba(131, 138, 158, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.32px;
}

.campaign-builder-sidenav-add-label-btn-big {
  background-color: rgba(31, 90, 123, 1);
  padding-top: 19px;
  padding-bottom: 19px;
  border-radius: 16px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.32px;
  cursor: pointer;
}

.campaign-builder-side-nav-icon-select {
  display: flex;
  gap: 30px;
  padding-top: 20px;
  padding-bottom: 5px;

  flex-wrap: wrap;
}

.campaign-builder-sidenav-line {
  height: 1px;
  background-color: rgba(255, 255, 255, 0.39);
  margin-bottom: 5px;
}

.campaign-builder-side-nav-prev-added {
  display: flex;
  justify-content: space-between;
  background-color: rgba(232, 239, 242, 1);
  padding: 14px 23px;
  border-radius: 16px;
}

.campaign-builder-side-nav-prev-added-left {
  display: flex;
  gap: 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.32px;
  font-weight: 500;
}

.campaign-builder-side-nav-bin {
  cursor: pointer;
}

.campaign-builder-side-nav-wrapper {
  height: 100%;
}

.campaign-builder-sidenav-back-to-menu-btn {
  color: #ffffff;
  border-radius: 16px;
  padding: 19px 1px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background-color: #1f5a7b;
  width: 100%;
}

.campaign-builder-sidenav-preview-btn {
  color: #ffffff;
  border-radius: 16px;
  padding: 19px 1px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid white;
  // background-color: #1f5a7b;

}

.highlight {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.32px;
}

.select-highlight-container {
  display: flex;
  gap: 4px;
}

.campaign-builder-sidenav-add-to-card {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #dcdcec;
}

.campaign-builder-sidenav-add-to-card-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 17px;
}

.campaign-builder-sidenav-agent-photo {
  position: relative;
  margin-top: 20px;
  width: fit-content;
}

.campaign-builder-sidenav-agent-photo .img {
  width: 111px;
  height: 111px;
  border-radius: 55px;
  background-position-y: 80%;
}

.campaign-builder-sidenav-agent-photo-cross {
  position: absolute;
  z-index: 2;
}

.campaign-builder-sidenav-create-agent-btn {
  background-color: rgba(255, 255, 255, 0.15);
  display: inline-block;
  padding: 12px 24px;
  border-radius: 20px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
  cursor: pointer;
  margin-bottom: 20px;
  width: fit-content;
}

.campaign-builder-sidenav-create-agent-btn-wrapper {
  display: flex;
  justify-content: center;
}

.replace-cross-wrapper {
  background-color: rgba(255, 255, 255, 0.33);
  position: absolute;
  top: 8px;
  cursor: pointer;
  border-radius: 20px;
  padding: 6px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
}

.update-highlight-btn {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 20px;
}

.campaign-builder-side-nav-prev-added {
  display: flex;
  justify-content: space-between;
  background-color: rgba(232, 239, 242, 1);
  padding: 14px 23px;
  border-radius: 16px;
}

.highlight-add-update-elem {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.highlight-add-update-elem-wrapper {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.highlight-custom-elem-button {
  display: flex;
  justify-content: start;
}

.highlight-custom-elem-button:focus,
.highlight-custom-elem-button:hover {
  background-color: #fff !important;
  cursor: default !important;
}

.campaign-builder-side-nav-prev-added-left {
  display: flex;
  gap: 7px;
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
  letter-spacing: -0.32px;
  font-weight: 500;
}

.campaign-builder-side-nav-bin {
  cursor: pointer;
}

.campaign-builder-side-nav-wrapper {
  height: 100%;
}

.campaign-builder-sidenav-back-to-menu-btn {
  color: #ffffff;
  border-radius: 16px;
  padding: 19px 1px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  background-color: #1f5a7b;
  width: 100%;
}

.campaign-builder-sidenav-preview-btn {
  color: #ffffff;
  border-radius: 16px;
  padding: 19px 1px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid white;
}

.highlight {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: -0.32px;
}

.select-highlight-container {
  display: flex;
  gap: 4px;
}

.campaign-builder-sidenav-add-to-card {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.32px;
  color: #dcdcec;
}

.campaign-builder-sidenav-add-to-card-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 17px;
}

.campaign-builder-sidenav-agent-photo {
  position: relative;
  margin-top: 20px;
  width: fit-content;
}

.campaign-builder-sidenav-agent-photo .img {
  width: 111px;
  height: 111px;
  border-radius: 55px;
  background-position-y: 80%;
}

.campaign-builder-sidenav-agent-photo-cross {
  position: absolute;
  z-index: 2;
}

.campaign-builder-sidenav-create-agent-btn {
  background-color: rgba(255, 255, 255, 0.15);
  display: inline-block;
  padding: 12px 24px;
  border-radius: 20px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.32px;
  cursor: pointer;
  margin-bottom: 20px;
  width: fit-content;
}

.campaign-builder-sidenav-create-agent-btn-wrapper {
  display: flex;
  justify-content: center;
}

.replace-cross-wrapper {
  background-color: rgba(255, 255, 255, 0.33);
  position: absolute;
  top: 8px;
  cursor: pointer;
  border-radius: 20px;
  padding: 6px;
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  box-sizing: border-box;
}
.p-types-slider {
    .swiper-wrapper {
        height: 100%;
    }

    .swiper-wrapper {
        height: 100%;
    }

    .swiper-slide-active {
        cursor: pointer;
    }

    .swiper-slide {
        transition: opacity 0.5s;
    }

    .swiper-slide-prev {
        opacity: 0;
    }

    .fade {

        opacity: 0;
    }
}

.alternate {
    opacity: 0;
    transition: opacity 0.5s;
}

.alfter-active {
    opacity: 1;
}

.consultation-container {
    background-color: rgba(3, 74, 115, 1);
    width: 100%;
    display: flex;
    justify-content: center;
}

.consultation-info-conatiner h3 {
    color: rgba(252, 188, 92, 1);
    font-weight: 600;
    margin-bottom: 5px;
}

.consultation-info-conatiner h2 {
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    text-transform: uppercase;

}

.consultation-info-conatiner h4 {
    color: rgba(255, 255, 255, 0.83);
    font-weight: 400;
}

.consultation-info-conatiner button {
    background-color: rgba(215, 161, 72, 1);

}
.overview-text-container {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    margin: 0 20px;
}

.overview-left-container {
    flex-direction: column;
    display: flex;
}

.overview-title-container {
    font-weight: 500;
    font-size: 46px;
    text-transform: uppercase;
}

.overview-title-colored-words {
    display: flex;
    line-height: 52px;
}

@keyframes changeColor1 {

    0%,
    100% {
        color: #D7A148;
    }

    33% {
        color: #4F819D;
    }

    66% {
        color: #034A73;
    }
}

@keyframes changeColor2 {

    0%,
    100% {
        color: #034A73;
    }

    33% {
        color: #D7A148;
    }

    66% {
        color: #4F819D;
    }
}

@keyframes changeColor3 {

    0%,
    100% {
        color: #4F819D;
    }

    33% {
        color: #034A73;
    }

    66% {
        color: #D7A148;
    }
}


.overview-title-colored-words-text1 {
    animation: changeColor1 3s infinite;
}

.overview-title-colored-words-text2 {
    animation: changeColor2 3s infinite;
}

.overview-title-colored-words-text3 {
    animation: changeColor3 3s infinite;
}

.overview-text-container p {
    font-size: 18px;
    font-weight: 400;
    color: #0000009E;
}

.overview-text-in-a-circle {
    animation: loop 10.5s linear infinite;
}

.overview-logo {
    position: absolute;
    top: 49%;
    left: 52%;
    transform: translate(-50%, -50%);
}

.overview-logo-container {
    position: relative;
    display: inline-block;
}

@keyframes loop {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.overview-hero-img-container {
    background: url(../assets/hero_img.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position-y: 80%;
    position: relative;
    display: flex;
    justify-content: center;
}

.overview-filter-main-filter-container {
    background-color: #000000D6;
    display: flex;
    justify-content: space-between;
    border-radius: 30px;
    padding: 11px 40px 11px 40px;
    gap: 25px;
    align-items: center;
}

.filter-ai-sparkles-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.xl-width {
    max-width: 1440px;
    width: 100%;
}

.xxl-width {
    max-width: 2360px;
    width: 100%;
}

.filter-ai-sparkles-container p {
    color: #68BFD2;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.5s ease;
}

.overview-filter-container {
    position: absolute;
    top: -99px;
    gap: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 900px;
    width: 100%;
}

.overview-filter-second-filter-container {
    background-color: #000000D6;
    border-radius: 61.5px;
    display: flex;
    justify-content: space-between;
    padding-top: 14px;
    padding-bottom: 14px;
    max-width: 900px;
    width: 100%;
}

@media(max-width: 995px) {
    .overview-filter-container {
        max-width: 790px;
        width: 100%;
    }
}

@media(max-width: 865px) {
    .overview-filter-container {
        max-width: 700px;
        width: 100%;
    }
}

.overview-filter-second-filter-container span {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFFC4;
}

.filter-location-container select,
.filter-property-type-container select,
.filter-price-range-container select,
.filter-status-container select {
    color: #fff;
    border: 0;
    font-weight: 500;
    font-size: 16px;
    appearance: none;
    background-color: transparent;
    outline: none;

}

.filter-location-container,
.filter-property-type-container,
.filter-price-range-container,
.filter-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;
    height: 46px;
}

.filter-location-container span,
.filter-property-type-container span,
.filter-price-range-container span,
.filter-status-container span {
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 15px;

}

.filter-location-container select option,
.filter-property-type-container select option,
.filter-price-range-container select option,
.filter-status-container select option {
    font-weight: 500;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.84);
}


.overview-filter-second-search-btn-container {
    background-color: #FCBC5C;
    width: 62px;
    height: 62px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.home-google-search-input {
    background-color: rgba(0, 0, 0, 0.8392156863);
    border: none;
    color: white;
    max-width: 220px;
    width: 100%;
    border-radius: 20px;
    font-size: 16px;
}

.home-google-search-input::placeholder {
    color: white;
}


.home-google-search-input:focus {
    outline: none;
}

.portfolio-google-search-input {
    background-color: rgba(240, 244, 247, 1);
    border: none;
    color: black;
    width: 100%;
    font-size: 16px;
    padding: 13px 12px 12px 20px;
    border-radius: 20px;
    box-sizing: border-box;
}

.portfolio-google-search-input:focus {
    outline: none;
}

.mobile-google-search-input {
    background-color: white;
    color: black;
    width: 100%;
    font-size: 14px;
    padding: 13px 12px 12px 20px;
    border-radius: 16px;
    border: 1px solid rgba(224, 229, 242, 1);
    box-sizing: border-box;
}

.mobile-google-search-input:focus {
    outline: 1px solid rgba(224, 229, 242, 1);
}

.location-search-absolute {
    position: relative;
    width: 100%;
}

.clear-location {
    position: absolute;
    // top: 30%;
    // right: 0px;
    background-color: #E8EFF2;
    width: 22px;
    height: 22px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.test-location-builder:focus {
    outline: none;

}
.dedicated-learning-center-hero-img-wrapper {
  max-width: 3000px;
  width: 100%;
}

.dedicated-learning-center-hero-img-info-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  // background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(5, 5, 5, 0.44) 46.98%);
  box-sizing: border-box;
  padding: 0 20px;
}


.dedicated-learning-center-back-btn {
  display: flex;
  color: rgba(252, 188, 92, 1);
  gap: 10px;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.dedicated-learning-center-addition-info {
  display: flex;
  gap: 10px;
}

.dedicated-learning-center-addition-info-wrapper {
  display: flex;
  color: rgba(252, 188, 92, 1);
  font-size: 18px;
  font-weight: 500;
  gap: 10px;
  line-height: 28px;
}

.dedicated-learning-center-title {
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  max-width: 784px;
}

.main-article-wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.article-sidebar {
  position: sticky;
  top: 107px;
  flex-shrink: 0;
  padding-top: 50px;
}

.article-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.article-sidebar li {
  margin-bottom: 15px;
  margin-top: 15px;
}

.article-sidebar li.active-arctile a {
  font-weight: bold;
  color: black;
}

.article-sidebar li a {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.56);
  text-decoration: none;
}

.article-sidebar-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 15px;
  color: rgba(0, 0, 0, 1);
}

.article-sidebar-line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
}

.article-chapter {
  font-weight: 600;
}

.article-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.62);
}

.related-arctiles-title {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 36px;
  line-height: 36px;
  text-align: center;
}
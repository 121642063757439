.map-card-popup-container {
    display: flex;
    gap: 10px;
}

.map-card-popup-container-price {
    color: rgba(3, 74, 115, 1);
    font-size: 14px;
    font-weight: 600;
    font-family: "DM Sans";
    margin-top: 9px;
}

.map-card-popup-container-main-img {
    width: 150px;
    height: 115px;
    border-radius: 15px;
    display: flex;
    position: relative;
}

.mapboxgl-popup-content {
    border-radius: 0 !important;
    border-top-right-radius: 25px !important;
    padding: 6px !important;
}

.map-card-popup-container-main-img-like {
    position: absolute;
    top: 8px;
    right: 8px;
}


.map-card-popup-container-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 2px;
    max-width: 142px;
}

.map-card-popup-info-price-and-like-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.map-card-popup-container-location {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: rgba(123, 131, 136, 1);
    margin-top: 7px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    display: none;
}

.control-map-panel-element-plus,
.control-map-panel-element-minus {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.control-map-panel-element-minus {
    padding: 18.5px 14.5px;

}

.control-map-panel-element-plus {
    padding: 14.5px 14.5px;
}

.control-map-panel-container {
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    top: 18px;
    right: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid rgba(225, 228, 241, 1);
    z-index: 2;
    border-radius: 5px;
}

.mapbox-search-container {
    position: relative;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;


}

.suggestions-list {
    z-index: 1;
    position: absolute;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: rgba(232, 239, 242, 1);
    font-size: 14px;
    transition: all 0.3s ease-in-out;

}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #eeeeee;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  height: 100%;
}

#root{
  height: 100%;
}

.App{
  height: 100%;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Frank Ruhl Libre start */
@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Frank Ruhl Libre';
  src: url('../src/fonts/frank-ruhl-libre/static/FrankRuhlLibre-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
/* Frank Ruhl Libre end */


/* DM Sans start */
@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../src/fonts/dm-sans/static/DMSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
/* DM Sans end */


/* Alef start*/
@font-face {
  font-family: 'Alef';
  src: url('../src/fonts/alef/Alef-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Alef';
  src: url('../src/fonts/alef/Alef-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
/* Alef end*/


@font-face {
  font-family: 'Noto Sans Hebrew';
  src: url('../src/fonts/noto-sans/NotoSansHebrew-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}



.campaign-builder-search-box-wrap input {
  height: 50px;
  padding: 0 25px;
}

.home-desktop-search-box-wrap input{
  padding: 5px 10px;
  border-radius: 20px;
  color: white;
  font-size: 15px;
}

.home-desktop-search-box-wrap svg{
display: none;
/* left: 0;
right: auto; */
}

.home-desktop-search-box-wrap input:focus{
  color: white;
  border: none;
}

.portfolio-desktop-search-box-wrap input{
  height: 47px;
  padding: 10px 25px;
  border-radius: 23px;
}

.portfolio-desktop-search-box-wrap input:focus{
  border: none;
}

.mobile-search-box-wrap input{
  height: 50px;
  padding: 0 25px;
}

.mobile-search-box-wrap input:focus{
  border: none;
}

.pac-container{
  z-index: 1300;
}
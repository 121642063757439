.auth-wrapper {
    display: flex;
    position: relative;
}

.signin-form-section {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
}

.signin-img-section {
    position: sticky;
    height: 100vh;
    top: 0;
    display: flex;
    flex: 1;
    width: 100%;
}

.signin-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}


.signin-form-container {
    max-width: 410px;
    width: 100%;
}

.signin-form-title-container {
    display: flex;
    align-items: baseline;
}

.signin-form-title-signin {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    color: rgba(0, 0, 0, 1);
    letter-spacing: -0.32px;
}

.signin-vertical-divider {
    width: 1px;
    height: 32px;
    background-color: rgba(224, 229, 242, 1);

}

.signin-form-title-welcom {
    color: rgba(120, 125, 153, 1);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 16px;
}

.signin-form-google-btn {
    background-color: rgba(232, 239, 242, 0.8);
    border-radius: 16px;
    color: rgba(43, 54, 116, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14.5px;
    margin-top: 25px;
    margin-bottom: 15px;
    cursor: pointer;
}

.signin-horizontal-line {
    height: 1px;
    background-color: rgba(224, 229, 242, 1);
    width: 100%;
}

.signin-form-or-container {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 15px;
}

.signin-form-or-text {
    font-size: 14px;
    color: rgba(163, 174, 208, 1);
    line-height: 24px;
    letter-spacing: -0.32px;
}

.signin-form-inputs-signin-btn {
    background-color: rgba(3, 74, 115, 1);
    color: rgba(255, 255, 255, 1);
    border: none;
    padding: 19px;
    border-radius: 16px;
    width: 100%;
    line-height: 16px;
    font-size: 16px;
    margin-top: 30px;
    cursor: pointer;
}

.signin-form-inputs-signin-btn:disabled {
    background-color: rgba(90, 130, 153, 0.716);
    color: rgba(255, 255, 255, 1);

}


.signin-form-inputs-signin-btn::first-letter {
    text-transform: uppercase;
}

.signin-form-inputs-label {
    color: rgba(43, 54, 116, 1);
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.32px;
    margin-bottom: 10px;
    font-weight: 500;
}

.signin-form-keep-me-logged-in-container {
    display: flex;
    align-items: center;
    gap: 11px;
}

.not-registred-yet-container {
    display: flex;
    gap: 5px;
    margin-top: 17px;
}

.signin-form-k-m-l-in-and-g-p-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.signup-additional-desc {
    color: rgba(120, 125, 153, 1);
    margin-top: 15px;
    font-weight: 400;
}

.signup-close-cross {
    padding: 5px;
    cursor: pointer;
    width: fit-content;
}

.submit-error {
    color: red;
    font-size: 14px;
}

.redirect-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
}

.back-btn-auth {
    color: rgba(3, 74, 115, 1);
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.back-btn-auth-wrapper {
    width: 100%;
}

.welcome-on-board-done-icon {
    border: 1px solid rgba(163, 174, 208, 1);
    padding: 12.2px 12px;
    border-radius: 10px;
    width: fit-content;

}
.hero-img-statistics-container {
    position: absolute;
    bottom: 0;
    gap: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    backdrop-filter: blur(25px);
    align-items: center;
    height: 139px;
}

.hero-img-statistics-item {
    display: flex;
    gap: 14px;
    align-items: center;
}

.hero-img-statistics-item {
    margin: 0;
}

.hero-img-statistics-quantity {
    background-color: rgba(255, 255, 255, 0.21);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 89px;
    height: 89px;
    border-radius: 44.5px;
    font-family: DM Sans;
    font-weight: 700;
    font-size: 22px;
    backdrop-filter: blur(30px);
    color: rgba(255, 255, 255, 1);
}

.hero-img-statistics-name {
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 24px;
}

.hero-img-statistics-container-down-md{
    position: absolute;
    bottom: 0;
    gap: 8px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    backdrop-filter: blur(25px);
    align-items: center;
    height: 78px;
}
.contact-us-hero-img-wrapper {
    max-width: 3000px;
    width: 100%;
}

.background-shadow {
    background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(5, 5, 5, 0.44) 46.98%);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.contact-us-hero-img-info-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    box-sizing: border-box;
    padding: 0 20px;
    color: rgba(255, 255, 255, 1);
    max-width: 1440px;
}

.contact-us-hero-img-info-wrapper-title {
    text-transform: uppercase;
    font-weight: 600;
}

.contact-us-hero-img-info-wrapper-desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}
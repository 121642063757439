.for-buyers-container {
    display: flex;
}

.for-buyers-info-conatiner {
    display: flex;
    flex-direction: column;
}

.for-buyers-info-conatiner h3 {
    color: rgba(79, 129, 157, 1);
    font-weight: 600;
    margin-bottom: 5px;
}

.for-buyers-info-conatiner h2 {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    text-transform: uppercase;

}

.for-buyers-info-conatiner h4 {
    color: rgba(0, 0, 0, 0.62);
    font-weight: 400;
}

.for-buyers-blue-text {
    font-weight: 700;
    color: rgba(3, 74, 115, 1);
    font-style: italic;
}

.for-buyers-black-text {
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}


.text-for-buyer-ltr::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    height: 1px;
    background-color: black;
}

.text-for-buyer-ltr::before {
    content: '';
    position: absolute;
    top: 50%;
    left: calc(100% + 0px);
    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-color: black;
    border-radius: 50%;
}

//lines ltr
.text-for-buyer-ltr h4 {
    display: inline;
}

.text-for-buyer-ltr {
    position: relative;
}

@media (min-width: 1440px) {
    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 168px;
        margin-left: 20px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 79px;
        margin-left: 50px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 233px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 119px;
        margin-left: 60px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 168px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 168px);
    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 150px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 150px);
    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 233px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 119px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 119px);
    }
}

@media (max-width: 1440px) and (min-width: 1024px) {
    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 120px;
        margin-left: 20px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 70px;
        margin-left: 40px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 150px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 90px;
        margin-left: 50px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 120px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 120px);
    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 70px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 70px);
    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 150px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 90px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 0px);

    }
}

@media (max-width: 1024px) and (min-width: 768px) {


    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 100px;
        margin-left: 15px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 60px;
        margin-left: 30px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 120px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 80px;
        margin-left: 40px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 100px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 60px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 120px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 80px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 0px);

    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .text-for-buyer-ltr h4 {
        font-size: 16px;
    }

    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 80px;
        margin-left: 10px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 50px;
        margin-left: 20px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 90px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 60px;
        margin-left: 30px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 80px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 50px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 90px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 60px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 0px);

    }
}

@media (max-width: 600px) {
    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 50px;
        margin-left: 5px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 30px;
        margin-left: 15px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 60px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 40px;
        margin-left: 20px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 50px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 30px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 60px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 40px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 0px);

    }
}



@media (max-width: 920px) {
    .text-for-buyer-ltr:nth-child(1) {
        margin-right: 50px;
        margin-left: 5px;
    }

    .text-for-buyer-ltr:nth-child(2) {
        margin-right: 30px;
        margin-left: 15px;
    }

    .text-for-buyer-ltr:nth-child(3) {
        margin-right: 60px;
    }

    .text-for-buyer-ltr:nth-child(4) {
        margin-right: 40px;
        margin-left: 20px;
    }

    .text-for-buyer-ltr::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-ltr::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-ltr:nth-child(1)::after {
        width: 50px;
    }

    .text-for-buyer-ltr:nth-child(1)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(2)::after {
        width: 30px;
    }

    .text-for-buyer-ltr:nth-child(2)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(3)::after {
        width: 60px;
    }

    .text-for-buyer-ltr:nth-child(3)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-ltr:nth-child(4)::after {
        width: 40px;
    }

    .text-for-buyer-ltr:nth-child(4)::before {
        right: calc(100% + 0px);

    }
}

@media (max-width: 830px) and (min-width: 600px) {
    .text-for-buyer-ltr h4 {
        font-size: 16px;
    }
}


//lines ltr



//lines rtl

.text-for-buyer-rtl::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 0%;
    height: 1px;
    background-color: black;
}

.text-for-buyer-rtl::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;

    transform: translateY(-50%);
    width: 9px;
    height: 9px;
    background-color: black;
    border-radius: 50%;
}

.text-for-buyer-rtl h4 {
    display: inline;
}

.text-for-buyer-rtl {
    position: relative;
}

@media (min-width: 1440px) {
    .text-for-buyer-rtl:nth-child(1) {
        margin-left: 168px;
        margin-right: 20px;
    }

    .text-for-buyer-rtl:nth-child(2) {
        margin-left: 79px;
        margin-right: 50px;
    }

    .text-for-buyer-rtl:nth-child(3) {
        margin-left: 233px;
    }

    .text-for-buyer-rtl:nth-child(4) {
        margin-left: 119px;
        margin-right: 60px;
    }

    .text-for-buyer-rtl::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-rtl::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-rtl:nth-child(1)::after {
        width: 168px;
    }

    .text-for-buyer-rtl:nth-child(1)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(2)::after {
        width: 150px;
    }

    .text-for-buyer-rtl:nth-child(2)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(3)::after {
        width: 233px;
    }

    .text-for-buyer-rtl:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(4)::after {
        width: 119px;
    }

    .text-for-buyer-rtl:nth-child(4)::before {
        right: calc(100% + 0px);
    }
}

@media (max-width: 1440px) and (min-width: 1024px) {
    .text-for-buyer-rtl:nth-child(1) {
        margin-left: 120px;
        margin-right: 20px;
    }

    .text-for-buyer-rtl:nth-child(2) {
        margin-left: 70px;
        margin-right: 40px;
    }

    .text-for-buyer-rtl:nth-child(3) {
        margin-left: 150px;
    }

    .text-for-buyer-rtl:nth-child(4) {
        margin-left: 90px;
        margin-right: 50px;
    }

    .text-for-buyer-rtl::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-rtl::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-rtl:nth-child(1)::after {
        width: 120px;
    }

    .text-for-buyer-rtl:nth-child(1)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(2)::after {
        width: 70px;
    }

    .text-for-buyer-rtl:nth-child(2)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(3)::after {
        width: 150px;
    }

    .text-for-buyer-rtl:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(4)::after {
        width: 90px;
    }

    .text-for-buyer-rtl:nth-child(4)::before {
        right: calc(100% + 0px);
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .text-for-buyer-rtl:nth-child(1) {
        margin-left: 55px;
    }

    .text-for-buyer-rtl:nth-child(2) {
        margin-left: 30px;
    }

    .text-for-buyer-rtl:nth-child(3) {
        margin-left: 60px;

    }

    .text-for-buyer-rtl:nth-child(4) {
        margin-left: 45px;
    }

    .text-for-buyer-rtl::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-rtl::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-rtl:nth-child(1)::after {
        width: 100px;
    }

    .text-for-buyer-rtl:nth-child(1)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(2)::after {
        width: 60px;
    }

    .text-for-buyer-rtl:nth-child(2)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(3)::after {
        width: 120px;
    }

    .text-for-buyer-rtl:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(4)::after {
        width: 80px;
    }

    .text-for-buyer-rtl:nth-child(4)::before {
        right: calc(100% + 0px);
    }
}

@media (max-width: 768px) and (min-width: 600px) {
    .text-for-buyer-rtl:nth-child(1) {
        margin-left: 80px;
        margin-right: 10px;
    }

    .text-for-buyer-rtl:nth-child(2) {
        margin-left: 50px;
        margin-right: 20px;
    }

    .text-for-buyer-rtl:nth-child(3) {
        margin-left: 90px;
    }

    .text-for-buyer-rtl:nth-child(4) {
        margin-left: 60px;
        margin-right: 30px;
    }

    .text-for-buyer-rtl::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-rtl::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-rtl:nth-child(1)::after {
        width: 80px;
    }

    .text-for-buyer-rtl:nth-child(1)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(2)::after {
        width: 50px;
    }

    .text-for-buyer-rtl:nth-child(2)::before {
        right: calc(100% + 0px);

    }

    .text-for-buyer-rtl:nth-child(3)::after {
        width: 90px;
    }

    .text-for-buyer-rtl:nth-child(3)::before {
        right: calc(100% + 0px);
    }

    .text-for-buyer-rtl:nth-child(4)::after {
        width: 60px;
    }

    .text-for-buyer-rtl:nth-child(4)::before {
        right: calc(100% + 0px);
    }
}

@media (max-width: 600px) {
    .text-for-buyer-rtl:nth-child(1) {
        margin-left: 50px;
        margin-right: 5px;
    }

    .text-for-buyer-rtl:nth-child(2) {
        margin-left: 30px;
        margin-right: 15px;
    }

    .text-for-buyer-rtl:nth-child(3) {
        margin-left: 60px;
    }

    .text-for-buyer-rtl:nth-child(4) {
        margin-left: 40px;
        margin-right: 20px;
    }

    .text-for-buyer-rtl::after {
        height: 1px;
        background-color: black;
    }

    .text-for-buyer-rtl::before {
        width: 9px;
        height: 9px;
        background-color: black;
        border-radius: 50%;
    }

    .text-for-buyer-rtl:nth-child(1)::after {
        width: 50px;
    }

    .text-for-buyer-rtl:nth-child(1)::before {
        right: calc(100% + 0px);
    }

}

@media (max-width: 830px) and (min-width: 600px) {
    .text-for-buyer-rtl h4 {
        font-size: 16px;
    }
}

@media (max-width: 930px) and (min-width: 600px) {
    .for-buyers-img {
        width: 300px;
    }
}
.top-arctile-section-wrapper {
    display: flex;
    gap: 25px;
    height: 520px;
    box-sizing: border-box;
    padding: 0 20px;
}

.main-blue-acticle-section-wrapper {
    background-color: rgba(3, 74, 115, 1);
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    height: 100%,
}

.main-blue-acticle-section-main-img {
    display: flex;
    flex: 1;
}

.main-blue-acticle-section-info-wrapper {
    flex: 1;
    padding-top: 30px;
    padding-bottom: 26px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.main-blue-acticle-clue-container {
    display: flex;
    gap: 10px;
}

.clue-grey {
    border: 1px solid rgba(168, 168, 168, 1);
    color: rgba(255, 255, 255, 1);
    width: fit-content;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    padding: 9px 16px;
}

.main-blue-acticle-section-title {
    color: rgba(255, 255, 255, 1);
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
}

.main-blue-acticle-section-desc {
    color: rgba(255, 255, 255, 0.8);
    line-height: 20px;
    font-size: 16px;
    width: 100%;
}

.main-blue-acticle-additional-info-wrapper {
    display: flex;
    gap: 10px;
    color: rgba(255, 255, 255, 0.88);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.main-blue-acticle-additional-info-line {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.55);
}

.white-middle-article-card {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.11);
    border-radius: 25px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    cursor: pointer;
}

.main-white-acticle-additional-info-line {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.11);
}

.main-white-acticle-additional-info-wrapper {
    display: flex;
    gap: 10px;
    color: rgba(3, 74, 115, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}

.white-middle-article-card-info-wrapper {
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.white-article-card-title {
    text-transform: uppercase;
    max-width: 377px;
    width: 100%;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    margin-top: 15px;
    white-space: normal;
    word-break: break-word;
}

.white-article-card-desc {
    margin-top: 10px;
    margin-bottom: 18px;
    color: rgba(0, 0, 0, 0.62);
    line-height: 22px;
}

.clue-blue {
    border: 1px solid rgba(125, 185, 219, 1);
    color: rgba(0, 0, 0, 1);
    width: fit-content;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    padding: 9px 16px;
}

.clue-container {
    display: flex;
    gap: 10px;
}

.additional-articles-swiper {
    height: 480px;
    box-sizing: border-box;
}

.additional-articles-swiper .swiper-slide {
    width: 450px;
}

.additional-articles-rtl .swiper-slide:last-child {
    margin-left: 0 !important;
}

.additional-articles-ltr .swiper-slide:last-child {
    margin-right: 0 !important;
}

.must-to-read-title {
    text-transform: uppercase;
    font-weight: 600;
}

.must-to-read-article-section {
    height: 517px;
    display: flex;
    gap: 26px;
}

.clue-white-blue {
    border: 1px solid rgba(125, 185, 219, 1);
    color: rgba(255, 255, 255, 1);
    width: fit-content;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    padding: 9px 16px;
    white-space: nowrap;
    cursor: pointer;
}

.must-read-main-arcticle-card {
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    flex: 1.65;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    height: 100%;
    cursor: pointer;
}

.must-read-main-arcticle-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.86) 0%, rgba(102, 102, 102, 0) 65.71%);
    z-index: 1;
    pointer-events: none;
}

.main-yellow-acticle-additional-info-wrapper {
    display: flex;
    gap: 10px;
    color: rgba(252, 188, 92, 1);
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.main-yellow-acticle-additional-info-line {
    width: 1px;
    background-color: rgba(255, 255, 255, 0.55);
}

.must-read-card-title {
    font-weight: 600;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 1);
}

.must-read-main-arcticle-card-info-wrapper {
    z-index: 2;
}

.white-article-card-desc {
    margin-top: 10px;
    margin-bottom: 18px;
    color: rgba(0, 0, 0, 0.62);
    line-height: 22px;
}

.must-read-card-desc {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.85);
}

.learning-center-must-to-read-section {
    box-sizing: border-box;
    padding: 0 20px;
}

.additional-articles-swiper .swiper-slide {
    flex: 1 0 0;
}

.swiper-slider-article {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 60px);
}

.swiper-slider-article .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute;
    bottom: 32px;
}

.swiper-slider-article .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.59);
}

.swiper-slider-article .swiper-pagination-bullet-active {
    background-color: white;
    background-color: rgba(13, 33, 14, 0.59);
}

.swiper-slider-article .swiper {
    height: 100%;
    padding-bottom: 20px;
}

.must-to-read-title-swiper-slider {
    height: 517px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 60px);
}

.must-to-read-title-swiper-slider .swiper {
    height: 100%;
    padding-bottom: 20px;
}

.must-to-read-title-swiper-slider .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute;
    bottom: 32px;
}

.must-to-read-title-swiper-slider .swiper-pagination-bullet-active {
    background-color: white;
}

.must-to-read-title-swiper-slider .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.59);
}

.clue-white-blue.active {
    border: 1px solid rgba(255, 255, 255, 1);
}

.no-articles-container {
    font-size: 16px;
    display: flex;
    justify-content: center;
}
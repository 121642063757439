.ask-ai-hints-container {
    display: flex;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    gap: 10px;
}

.ask-ai-hints-container p {
    border-radius: 20px;
    padding: 8px 20px;
    cursor: pointer;
}
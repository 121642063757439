.property-card-container {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.11);
    border-top-right-radius: 25px;
}

.property-card-container-card {
    height: 250px;
    border-top-right-radius: 25px;

}

.property-card-additional-info {
    padding: 30px 25px 22px 25px;
}

.property-card-price {
    color: rgba(215, 161, 72, 1);
    font-size: 18px;
}

.property-card-price-and-heart-container {
    display: flex;
    justify-content: space-between;
}

.property-card-price-and-heart-container-anonymous {
    display: flex;
    justify-content: flex-end;
}

.property-card-titles-location {
    font-size: 16px;
    color: rgba(123, 131, 136, 1);
    line-height: 16px;
}

.property-card-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 12px;
    margin-top: 17px;
}

.property-card-detail {
    display: flex;
    font-weight: 400;
    font-size: 16px;
    align-items: center;
    gap: 10px;

}
.wishlist-count-of-items {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: rgba(105, 105, 105, 0.7);
}

.wishlist-count-of-items-container{
    display: flex;
    width: 100%;
    gap: 12px;
    align-items: baseline;
}
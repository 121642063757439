.overview-filter-down-md-container {
    position: absolute;
    top: calc(-65px / 2);
    gap: 8px;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.84);
    border-radius: 61.5px;
    padding-top: 7px;
    padding-bottom: 7px;
    width: 213px;
    justify-content: space-between;
}

.overview-filter-down-search-btn-container {
    background-color: #FCBC5C;
    width: 40px;
    height: 40px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.overview-filter-down-md-container p {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    margin: 0;
}

.overview-filter-down-search-btn-container img {
    width: 16px;
    height: 16px;
}
@import "./styles/home.scss";
@import "./styles/askAiPopover.scss";
@import "./styles/overviewFilterDownMd.scss";
@import "./styles/filterSideBar.scss";
@import "./styles/heroImgStatistics.scss";
@import "./styles/featuredProperties.scss";
@import "./styles/exporeBlock.scss";
@import "./styles/consultation.scss";
@import "./styles/forBuyers.scss";
@import "./styles/footer.scss";
@import "./styles/forBueyrsDownMd.scss";
@import "./styles/exploreTopListing.scss";
@import "./styles/getInTouch.scss";
@import "./styles/testimonial.scss";
@import "./styles/portfolio/portfolioHeroImg.scss";
@import "./styles/portfolio/PortfoliofilterDownMd.scss";
@import "./styles/portfolio/portfolioMobileFilter.scss";
@import "./styles/portfolio/propertyCard.scss";
@import "./styles/portfolio/portfolio.scss";
@import "./styles/auth/signin.scss";
@import "./styles/auth/forgotpassword.scss";
@import "./styles/auth/email-confirmation.scss";
@import "./styles/auth/sms-confirmation.scss";
@import "./styles/_common/map.scss";
@import "./styles/campaignBuilder/campaignBuilderSideNav.scss";
@import "./styles/campaignBuilder/campaignBuilderMain.scss";
@import "./styles/campaignBuilder/campaignViewMode.scss";
@import "./styles/campaignBuilder/contactToAgent.scss";
@import "./styles/aiAsistant/aiAsistant.scss";
@import "./styles/learningCenter/learningCenter.scss";
@import "./styles/learningCenter/browseTheLibrary.scss";
@import "./styles/learningCenter/galleryOfArticles.scss";
@import "./styles/learningCenter/dedicatedLearningCenter.scss";
@import "./styles/wishlist/wishlist.scss";
@import "./components/contactUs/styles/contactUsHeroImg.scss";
@import "./components/contactUs/styles/contactUsMainContainer.scss";
@import "./styles/campaignBuilder/aiChat.scss";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.campaign-builder-sidenav-back-header-img {
  filter: brightness(0) invert(0);
}

.App-link {
  color: #61dafb;
}
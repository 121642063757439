:root {
  // --contexify-menu-bgColor: #fff;
  // --contexify-separator-color: rgba(0,0,0,.2);
  --contexify-item-color: #1B2559;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #053957;
  // --contexify-rightSlot-color: #6f6e77;
  // --contexify-activeRightSlot-color: #fff;
  // --contexify-arrow-color: #6f6e77;
  // --contexify-activeArrow-color: #fff;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.ai_open_btn {
  position: fixed;
  z-index: 100000;
  bottom: 20px;
  right: 500px;
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background: linear-gradient(90deg, #066094 22.19%, #3AEBD1 100%);
  border: 1px solid #fbdab1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: heartbeat 2s infinite ease-in-out;

  &.left {
    left: 500px;
    right: 0px;

    &.sideberHideleftPosition {
      left: 50px;
    }
  }

  &.sideberHidePosition {
    right: 50px;
  }
}




.ai_chat {
  width: 512px;
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  transform: scaleY(0);
  transform-origin: bottom;
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  box-shadow: 14px 17px 40px 4px #7090B014;
  background: linear-gradient(90deg, #066094 22.19%, #3AEBD1 100%);
  border-radius: 30px;
  position: fixed;
  z-index: 100000;
  bottom: 20px;
  right: 500px;
  padding: 1px;
  box-shadow: 14px 17px 40px 4px #7090B014;

  &.left {
    left: 500px;
    right: 0px;

    &.sideberHideleftPosition {
      left: 50px;
    }
  }

  &.sideberHidePosition {
    right: 50px;
  }

  .ai_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 23px 20px;
    border-bottom: 1px solid #E9EDF7;

    h2 {
      margin: 0px;
      color: #1B2559;
      font-size: 18px;
      font-weight: 600;
      font-family: "DM Sans";
    }

    .icon_div {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }
  }
.parrant_body_div{
  width: 512px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  border-radius: 30px;
}
  .ai_body {
    max-height: calc(100vh - 260px);
    height: 100%;
    padding: 20px 20px 35px 20px;
    background-color: rgba(255, 0, 0, 0);
    overflow-y: auto;
    scrollbar-width: none;
  }
  .MuiInputBase-input{
    scrollbar-width: none;
  }
}

.ai_chat.open {
  transform: scaleY(1);
  opacity: 1;
}

.ai_chat.closed {
  transform: scaleY(0);
  opacity: 0;
}


.assistent {
  margin-bottom: 10px;

  .assistent_first_child {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .img_box {
      border: 1px solid #CCD5EB;
      height: 46px;
      width: 46px;
      border-radius: 7px;
      display: flex;
      align-items: end;
      justify-content: center;
      margin: 0px 10px 0px 0px;
    }

    h3 {
      color: #1B2559;
      font-size: 16px;
      font-weight: 500;
      font-family: "DM Sans";
      margin: 0px 10px;

    }
  }


  .assistent_second_child {
    border: 1px solid #E0E5F2A6;
    border-radius: 0px 14px 14px 14px;
    background-color: #EBF1FF57;
    padding: 17px;
    display: inline-block;

    p {
      color: #1B2559;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-family: "DM Sans";
      margin: 0px;
      white-space: pre-line;

    }
  }
}

button.quick_btn {
  display: flex;
  align-items: center;
  column-gap: 8px;
  
  border-radius: 17px;
  border: 1px solid #E8ECF7;
  background-color: #053957;
  color: #ffffff;
  height: 34px;
  padding: 0px 20px;
  cursor: pointer;
  transition: 0.3;
&.left{ 
  position: absolute;
  top: -20px;
  left: 20px;
}
&.right{
  position: absolute;
  top: -20px;
  right: 20px;
}
  &:hover {
    transition: 0.3;
    transform: scale(1.03);
  }
}

.user_message {
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 24px 0px;

  .user_message_first_child {
    border: 1px solid #E0E5F2A6;
    border-radius: 14px;
    background-color: #EBF1FF57;
    padding: 17px;
    display: inline-block;

    p {
      color: #1B2559;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-family: "DM Sans";
      margin: 0px;

    }
  }
}

.ai_footer {
  position: relative;
  padding: 20px;
  border-radius: 0px 0px 30px 30px;

  .footer_item {
    border: 1px solid #CCD5EB;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    padding: 7px 7px 7px 20px;
    .css-1ll44ll-MuiOutlinedInput-notchedOutline{
      outline: 0px !important;
      border: 0px ;
    }
    .prompt_search {
      padding-right: 20px;
      outline: 0px;
      border: none;
      background-color: transparent;
      width: 100%;
      font-size: 16px;
      font-family: "DM Sans";
    
      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #1B255961;
      }

    }

    div {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .file_upload,
      .record,
      .send {
        cursor: pointer;
        padding: 0px;
        border: 0px;
        background-color: transparent;
      }

      .file_upload,
      .record {
        opacity: 0.5;
        cursor: default;
      }

      .send {
        height: 38px;
        width: 38px;
        background-color: #EBF1FF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 5px;
      }
    }
  }
}

.button_item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: absolute;
  top: -20px;

  button {
    border-radius: 17px;
    border: 1px solid #E8ECF7;
    height: 34px;
    padding: 0px 20px;
    cursor: pointer;
    transition: 0.3;

    &:hover {
      transition: 0.3;
      transform: scale(1.03);
    }
  }
}

.quick_actions {
  margin-top: 25px;

  h4 {
    color: #1B2559B2;
    font-size: 14px;
    font-family: "DM Sans";
    font-weight: 500;
    margin-bottom: 12px;
  }

  .quick_actions_btn {
    padding: 15px 20px;
    border: 1px solid #E0E5F2A6;
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-radius: 14px;
    margin-bottom: 15px;
    background-color: transparent;
    width: 100%;
    cursor: pointer;

    p {
      color: #1B2559;
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 500;
      margin: 0px;
      
    }
  }
}

.currentsection_text{
  color: #1B2559;
      font-size: 16px;
      font-family: "DM Sans";
      font-weight: 500;
      margin: 0px 5px 5px 0px;
      position: absolute;
      top: -5px;
}

.contexify_itemContent span {
  color: '#fff' !important;
}


.custom-context-menu {
  // --contexify-menu-bgColor: #fff;
  // --contexify-separator-color: rgba(0, 0, 0, 0.2);
  // --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #053957;
  /* Example: Change active item background */
  // --contexify-rightSlot-color: #6f6e77;
  // --contexify-activeRightSlot-color: #fff;
  // --contexify-arrow-color: #6f6e77;
  // --contexify-activeArrow-color: #fff;
}
.portfolio-mobile-filter-container {
  padding: 0 20px;
  padding-bottom: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
}

.portfolio-mobile-search-container-icon {
  background-color: rgba(3, 74, 115, 1);
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.portfolio-mobile-search-container-icon img {
  width: 16.5px;
  height: 16.5px;
}

.portfolio-mobile-filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 6px;
}

.portfolio-mobile-filter-item {
  border: 1px solid rgba(0, 0, 0, 0.11);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5.5px 15px 5.5px 20px;
  border-radius: 15px;
  text-wrap: nowrap;
}

.portfolio-mobile-filter-item-cross-container {
  width: 30px;
  display: flex;
  justify-content: flex-end;
}

.portfolio-mobile-filter-item-cross-container img {
  width: 16px;
  height: 16px;
}

.portfolio-mobile-filter-item-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 1);
}

.layout-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  gap: 20px;
}

.property-card {
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease-out forwards;
}
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.map-container {
  display: none;
  position: sticky;
  left: 0;
}

.map-open {
  grid-template-columns: 60% 40%;
}

.map-open .map-container {
  display: block;
}

.portfolio-hero-img-wrapper {
    background: url(../../assets/portfolio_main_img.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
    max-width: 3000px;
}

.portfolio-hero-img-container {
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    // padding: 93px 20px 94px 20px;
}

.portfolio-hero-img-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.36);
    z-index: 1;
    pointer-events: none;
}

.porfolio-filter-container {
    gap: 8px;
    display: flex;
    flex-direction: column;
    min-height: 182px;
}

.portfolio-filter-ai-sparkles-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    padding: 14px 20px;
    border-radius: 24px;
    background-color: rgba(240, 244, 247, 1);
    height: fit-content;
    justify-content: center;
}

.porfolio-filter-second-filter-container {
    background-color: rgba(255, 255, 255, 0.82);
    display: flex;
    justify-content: space-between;
    border-radius: 50px;
    padding-top: 14px;
    padding-bottom: 14px;
}

portfolio-filter-location-container select,
.portfolio-filter-property-type-container select,
.portfolio-filter-price-range-container select,
.portfolio-filter-status-container select {
    color: rgba(0, 0, 0, 1);
    border: 0;
    font-weight: 500;
    font-size: 16px;
    appearance: none;
    background-color: transparent;
    outline: none;

}

.portfolio-filter-location-container,
.portfolio-filter-property-type-container,
.portfolio-filter-price-range-container,
.portfolio-filter-status-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 15px;
    height: 46px;
    align-items: baseline;
}

.portfolio-filter-location-container span,
.portfolio-filter-property-type-container span,
.portfolio-filter-price-range-container span,
.portfolio-filter-status-container span {
    color: rgba(0, 0, 0, 0.59);
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 15px;
    font-weight: 500;
}

.portfolio-filter-location-container select option,
.portfolio-filter-property-type-container select option,
.portfolio-filter-price-range-container select option,
.portfolio-filter-status-container select option {
    font-weight: 500;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.84);
}

.portfolio-filter-second-search-btn-container {
    background-color: rgba(3, 74, 115, 1);
    width: 46px;
    height: 46px;
    min-width: 46px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.portfolio-filter-second-search-btn-container img {
    width: 16.5px;
    height: 16.5px;
}

.portfolio-map-switcher-container {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.59);
}

.see-map-and-filters-container {
    font-weight: 500;
    position: fixed;
    bottom: 10px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    z-index: 20;
}

.see-map-and-filters-item {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(215, 161, 72, 1);
    padding: 9px 20px;
    border-radius: 30px;
    cursor: pointer;
}

.portfolio-header-sticky-wrapper {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.06);
}

.portfolio-filter-desktop-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    // padding-top:13px;
    align-items: center;
}

.porfolio-filter-name {
    font-weight: 500;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.59);
}

.potfolio-filter-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    // max-width: 350px;
    width: 100%;
}

.portfolio-price-range-container {
    background-color: rgba(240, 244, 247, 1);
    padding: 14px 20px;
    border-radius: 23px;
    display: flex;
}

.portfolio-price-range-container-price {
    display: flex;
}

.portfolio-filter-price-range-container-test {
    position: absolute;
    top: 80px;
    border-radius: 45px;
    padding: 5px 35px;
    width: 320px;
}

.price-range-panel-wrapper {
    position: relative;
}

.price-range-panel-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.email-confirmation-section {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
}

.email-confirmation-email-checked-container {
  border: 1px solid rgba(163, 174, 208, 1);
  padding: 17px 13px 12px 13px;
  border-radius: 10px;
  width: fit-content;
}

.email-confirmation-grey {
  color: rgba(120, 125, 153, 1);
  font-weight: 400;
  line-height: 16px;
}

.email-confirmation-we-sent-container {
  display: flex;
  margin-top: 15px;
}

.email-confirmation-blue {
  color: rgba(43, 54, 116, 1);
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
}

.email-confirmation-please-confirm-container {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-confirmation-dont-receive-container {
  display: flex;
  gap: 5px;
}

.email-confirmation-yellow {
  color: rgba(215, 161, 72, 1);
  font-weight: 600;
  line-height: 16px;
  cursor: pointer;
}

.email-confirmation-back-to-signup {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.choose-verification-container {
  height: 100px;
  width: 200px;
  display: flex;
  flex-direction: row;
  border: 1px #e0e5f2 solid;

  :hover {
    border: 1px #034a73 solid;
  }
}

.verification-option {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #d1d1d1;
  border-radius: 15px;
  margin-bottom: 10px;
  background-color: #fbfdfd;
  margin-top: 10px;
  width: 400px;
  cursor: pointer;
}

.verification-option img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.verification-option div {
  display: flex;
  flex-direction: column;
}

.verification-option span {
  font-size: 16px;
  font-weight: bold;
}

.verification-option p {
  margin: 0;
  font-size: 14px;
  color: #555;
}
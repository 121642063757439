.contact-to-agent-btn-wrapper {
    background-color: rgba(255, 255, 255, 1);
    position: fixed;
    bottom: 10px;
    border: 1px solid rgba(215, 161, 72, 1);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 21px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
    z-index: 20;
}

.contact-information-cross-close {
    margin-bottom: 20px;
    justify-content: end;
    display: flex;
}

.contact-to-agent-location-contaniner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.contact-to-agent-title {
    font-size: 22px;
    line-height: 22px;
    font-weight: 600;
}

.contact-to-agent-location {
    color: rgba(0, 0, 0, 0.62);
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}

.contact-information-cross-close-icon {
    cursor: pointer;
}